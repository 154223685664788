import React, { useEffect, useState } from "react";
import axios from "axios";
import '../assets/styles/styles.scss';
import { useAuth } from '../context/AuthContext';
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Calendar from "../Components/Calendar/Calendar";
import SideBar from "../Components/SideBar/SideBar";
import SiteNav from "../Components/SiteNav/SiteNav";
import AdminPanel from "../Components/AdminPanel/AdminPanel";
import SiteSelector from "../Components/SiteSelector/SiteSelector";


function UserDashboard() {
    const { currentUser } = useAuth();
    //console.log("Current User in Dashboard:", currentUser);
    const [showAdmin, setShowAdmin] = useState(false);
    const handleAdminClick = () => {
        setShowAdmin(!showAdmin);
    };

    console.log("Show Admin status:", showAdmin);
    return (

        <div>
            <Header />
            <div className="dashboard-body">
                <div className="dashboard-body__sidebar">
                    <SideBar className="dashboard-body__sidebar" onAdminClick={handleAdminClick} />
                </div>
                <div>
                    <Calendar className="dashboard-body__calendar" />
                </div>
            </div>
            <SiteNav />
            {showAdmin ? <AdminPanel /> : <SiteSelector />}
            <footer>
                <Footer />
            </footer>
        </div>
    );
}

export default UserDashboard;