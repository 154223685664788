import './SignUp.scss';
import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext';

function SignUp() {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth(); // Destructure login function from context

    function toggleForm() {
        setIsLoggingIn(!isLoggingIn);
        navigate(isLoggingIn ? '/' : '/login');
    }

    const registerSubmit = async (e) => {
        e.preventDefault();
        const org_id = e.target.organization.value;

        try {
            const orgResponse = await axios.get(`https://skedteam.com/orgs`);
            const orgIDs = orgResponse.data.map(org => org.id);

            if (!orgIDs.includes(parseInt(org_id))) {
                alert("Error: Organization ID is invalid. Contact your company admin.");
                return;
            }
        } catch (error) {
            console.error("Failed to verify organization ID:", error);
            alert("An error occurred while verifying the organization ID. Please try again.");
            return;
        }

        const newUser = {
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            org_id: org_id,
            telephone: e.target.telephone.value,
            email: e.target.email.value,
            password: e.target.password.value,
        };
        console.log(newUser);
        try {
            const response = await axios.post(`https://www.skedteam.com/users`, newUser);
            alert(`Registration successful`);
            console.log(response);
            setIsLoggingIn(prev => !prev);
        } catch (error) {
            console.error("Registration error:", error);
            alert(`An error occurred whilst registering, please try again or contact your team admin.`);
        }
    };

    const loginSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.login_email.value;
        const password = e.target.login_password.value;

        try {
            await login(email, password);
            navigate('/dashboard'); // Redirect on successful login
        } catch (error) {
            console.error('Login error:', error.response ? error.response.data : error);
            alert('Failed to log in. Please check your credentials and try again.');
        }
    };



    if (isLoggingIn) {
        return (
            <main className="modal">
                <form className="modal__form" onSubmit={loginSubmit}>
                    <button type='button' onClick={toggleForm} className="modal__form--close"></button>
                    <h2>LOGIN</h2>
                    <label htmlFor="login_email">Email</label>
                    <input placeholder="YourEmail@email.com" type="email" name="login_email" required />

                    <label htmlFor="login_password">Password</label>
                    <input placeholder="*********" type="password" name="login_password" required />

                    <button className="modal__form--button" type="submit">Login</button>
                </form>
            </main>
        );
    }

    return (
        <main className="modal">
            <form className="modal__form" onSubmit={registerSubmit}>
                <button type='button' onClick={toggleForm} className="modal__form--close"></button>
                <h2>REGISTER</h2>
                <label htmlFor="first_name">First Name</label>
                <input placeholder="Theresa" type="text" name="first_name" required />

                <label htmlFor="last_name">Last Name</label>
                <input placeholder="Green" type="text" name="last_name" required />

                <label htmlFor="email">Email</label>
                <input placeholder="TheresaG@email.com" type="email" name="email" required />

                <label htmlFor="password">Password</label>
                <input placeholder="*********" type="password" name="password" required />

                <label htmlFor="telephone">Telephone</label>
                <input placeholder="+44 74129 48315" type="tel" name="telephone" required />

                <label htmlFor="organization">Organization</label>
                <input placeholder="102548" type="text" name="organization" required />

                <button className="modal__form--button" type="submit">Register</button>
            </form>
        </main>
    );
}

export default SignUp;
