import React, { useState } from 'react';
import './SideBar.scss';
import { useAuth } from '../../context/AuthContext';
import lottie from 'lottie-web';
import { useNavigate } from 'react-router-dom'
import { defineElement } from '@lordicon/element';
import axios from 'axios';
defineElement(lottie.loadAnimation);

function SideBar({ onAdminClick }) {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    console.log("Current User in SideBar1:", currentUser);
    const [isOpen, setIsOpen] = useState(true);

    const toggleOpen = () => setIsOpen(!isOpen);

    const logoutUser = async () => {
        try {
            await axios.get('https://skedteam.com/logout');
            alert("Seeya!")
            navigate("/login")
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };


    return (
        <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
            {[
                { src: "https://cdn.lordicon.com/wzrwaorf.json", label: "Chat" },
                { src: "https://cdn.lordicon.com/dxjqoygy.json", label: "Team" },
                { src: "https://cdn.lordicon.com/nocovwne.json", label: "Forms" },
                { src: "https://cdn.lordicon.com/qvyppzqz.json", label: "Clock-in" },
                { src: "https://cdn.lordicon.com/szqqxgqw.json", label: "Holidays" },
                { src: "https://cdn.lordicon.com/ojnjgkun.json", label: "Help" },
            ].map((icon, index) => (
                <div className="menu-icons" key={index}>
                    <lord-icon
                        src={icon.src}
                        trigger="hover"
                        colors="primary:#121331,secondary:#08a88a"
                        style={{ width: '45px', height: '45px' }}
                    ></lord-icon>
                    {isOpen && <h3>{icon.label}</h3>}
                </div>
            ))}

            {/* Admin icon, shown only if user is admin */}
            {currentUser && currentUser.isAdmin && (
                <div className="menu-icons" onClick={onAdminClick}>
                    <lord-icon
                        src="https://cdn.lordicon.com/rhprarly.json"
                        trigger="hover"
                        style={{ width: '45px', height: '45px' }}
                    ></lord-icon>
                    {isOpen && <h3>Admin</h3>}
                </div>
            )}
            <div className="menu-icons" onClick={logoutUser}>
                <lord-icon
                    src="https://cdn.lordicon.com/gwvmctbb.json"
                    trigger="hover"
                    colors="primary:#121331,secondary:#08a88a"
                    style={{ width: '45px', height: '45px' }}
                ></lord-icon>
                {isOpen && <h3>Logout</h3>}
            </div>

            <div className="menu-icons menu-icons__last" onClick={toggleOpen}>
                <lord-icon
                    src="https://cdn.lordicon.com/axacjdbs.json"
                    trigger="hover"
                    colors="primary:#121331,secondary:#08a88a"
                    style={{ width: '45px', height: '45px' }}
                ></lord-icon>
            </div>
        </div>
    );
}

export default SideBar;
