// src/context/AuthContext.js
import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    const login = async (email, password) => {
        try {
            const response = await axios.post('https://www.skedteam.com/login', {
                email,
                password
            }, {
                withCredentials: true
            });
            console.log("Login successful, setting user:", response.data.user);
            setCurrentUser(response.data.user);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const logout = async () => {
        try {
            await axios.post('https://www.skedteam.com/logout', {}, { withCredentials: true });
            setCurrentUser(null);
        } catch (error) {
            console.error("Logout Error", error);
        }
    };

    const value = {
        currentUser,
        login,
        logout
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
