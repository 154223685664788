import "./Footer.scss"


function Footer() {
    return (
        <footer className="footer">
            <div className="footer__container">
                <p className="footer__text">© Sked. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer