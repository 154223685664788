import React, { useState } from 'react';
import './SiteNav.scss';
import lottie from 'lottie-web';
import { defineElement } from '@lordicon/element';
defineElement(lottie.loadAnimation);

function SiteNav() {


    return (
        <div className='nav-bar'>

        </div>
    );
}

export default SiteNav;
