import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import { ShiftProvider } from './context/ShiftContext'; // Adjust the path as necessary
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import SignUp from "./Components/SignUp/SignUp";
import UserDashboard from "./pages/Dashboard";
const url = process.env.API_URL;

function App() {
  return (
    <>
      <BrowserRouter>

        <section className="page-container__background">
          <main className="page-container">
            <AuthProvider>
              <ShiftProvider>
                <Routes>
                  <Route path="/" element={<SignUp url={url} />} />
                  <Route path="/login" element={<SignUp url={url} />} />
                  <Route path="/dashboard" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
                </Routes>
              </ShiftProvider>

            </AuthProvider>
          </main>
        </section>
      </BrowserRouter>
    </>
  );
}

export default App;