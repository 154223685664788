import "./Header.scss"
import logo1 from "../../assets/logo/sked_logo_transp_background.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';


function Header() {
    const navigate = useNavigate();
    const path = useLocation()

    const warehouse = "/warehouse"
    const inventory = "/inventory" || "/item"
    const classNameFunc = () => (path.pathname.includes(warehouse) || path.pathname.length === 1 ? "active_link" : "header__link");
    const classNameFunc1 = () => (path.pathname.includes(inventory) || path.pathname.includes("/item") ? "active_link" : "header__link");

    const logoutUser = async () => {
        try {
            await axios.get('https://skedteam.com/logout');
            alert("Seeya!")
            navigate("/login")
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <header className="header">
            <nav className="header__nav">
                <div className="header__logo-container">
                    <img className="header__logo" src={logo1} alt="Sked Logo" />
                </div>
                <ul className="header__nav-list">
                    <li className="header__nav-item">
                        <NavLink className={classNameFunc} to="/" >Profile</NavLink>
                    </li>
                    <li className="header__nav-item">
                        <NavLink className={classNameFunc1} onClick={logoutUser} >Logout</NavLink></li>
                </ul>

            </nav>
        </header>
    );
}

export default Header;