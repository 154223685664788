import deleteIcon from "../../assets/icons/delete_outline-24px.svg";
import editIcon from "../../assets/icons/edit-24px.svg";
import sortIcon from "../../assets/icons/sort-24px.svg";
import chevronIcon from "../../assets/icons/chevron_right-24px.svg";
import React, { useState, useEffect } from 'react';
import './SiteSelector.scss';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import { useShift } from '../../context/ShiftContext';

function SiteSelector({ url }) {
    const { shiftsUpdated } = useShift();
    const { currentUser } = useAuth();
    const [shifts, setShifts] = useState([]);

    useEffect(() => {
        const fetchShifts = async () => {
            try {
                const { data } = await axios.get(`https://www.skedteam.com/shifts`);
                console.log(data);
                const transformedShifts = data.map(shift => ({
                    staffId: `${shift.user_id}`,
                    date: new Date(shift.shift_date),
                    time: shift.shift_time,

                }));
                console.log(transformedShifts);
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                console.log(today);
                console.log(currentUser.user_id);
                const upcomingShifts = transformedShifts.filter(shift =>
                    shift.date >= today && shift.staffId == currentUser.user_id
                );
                console.log(upcomingShifts);
                setShifts(upcomingShifts);
            } catch (error) {
                console.error('Failed to fetch shifts:', error);
            }
        };

        fetchShifts();
    }, [shiftsUpdated, currentUser.id]);

    return (
        <main className="list-details">
            <h3 className="heading">Upcoming Shifts</h3>
            <ul className="sort-bar">
                <li className="sort-bar__element">
                    Date
                    <img src={sortIcon} alt="sort" />
                </li>
                <li className="sort-bar__element">
                    Time
                    <img src={sortIcon} alt="sort" />
                </li>
                <li className="sort-bar__element">
                    Site
                    <img src={sortIcon} alt="sort" />
                </li>
            </ul>
            <ul>
                {shifts.map((shift, index) => (
                    <li key={index} className="shift-entry">
                        <span>{shift.date.toLocaleDateString()}</span>
                        <span>{shift.time}</span>
                        <span>Albert Road</span> {/* Hardcoded site name */}
                    </li>
                ))}
            </ul>
        </main>
    );
};

export default SiteSelector;
