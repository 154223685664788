// Create a new context in a file, e.g., ShiftContext.js
import React, { createContext, useContext, useState } from 'react';

const ShiftContext = createContext();

export const useShift = () => useContext(ShiftContext);

export const ShiftProvider = ({ children }) => {
    const [shiftsUpdated, setShiftsUpdated] = useState(false);

    const updateShifts = () => {
        setShiftsUpdated(!shiftsUpdated); // Toggle to trigger useEffect
    };

    return (
        <ShiftContext.Provider value={{ shiftsUpdated, updateShifts }}>
            {children}
        </ShiftContext.Provider>
    );
};
