import React, { useState, useEffect } from 'react';
import './AdminPanel.scss';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useShift } from '../../context/ShiftContext';

function AdminPanel({ url }) {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const { updateShifts } = useShift();
    const [shiftDate, setShiftDate] = useState(new Date());
    const [startTime, setStartTime] = useState('');
    const [finishTime, setFinishTime] = useState('');
    const [activeTab, setActiveTab] = useState('Add Shift');
    const [repeatFrequency, setRepeatFrequency] = useState('');
    const [repeatDuration, setRepeatDuration] = useState('');
    const [shiftTimes] = useState([
        '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
        '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
        '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30'
    ]);



    useEffect(() => {
        axios.get(`https://www.skedteam.com/users`)
            .then(res => {
                setEmployees(res.data);
                console.log(res.data);
            })
            .catch(err => {
                console.error('Failed to fetch employees:', err);
            });
    }, []);

    const handleNavClick = (tabName) => {
        setActiveTab(tabName);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("employee list:", employees)
        const newShift = {
            employee_id: selectedEmployee,
            date: shiftDate.toISOString().substring(0, 10),
            start_time: startTime,
            finish_time: finishTime,
            repeat_frequency: repeatFrequency,
            repeat_duration: repeatDuration
        };
        console.log("New Shift:", newShift);

        try {
            await axios.post(`https://www.skedteam.com/new-shift`, newShift);
            alert('Shift added successfully');
            updateShifts();


        } catch (error) {
            console.error('Failed to add shift:', error);
            alert('Failed to add shift');
        }

    };

    return (
        <div className='admin-panel'>
            <nav className="admin-panel__nav">
                <ul className="admin-panel__nav-list">
                    {['Add Shift', 'Edit Shift', 'Delete Shift', 'Sites'].map((item) => (
                        <li key={item}
                            className={`admin-panel__nav-item ${activeTab === item ? 'admin-panel__nav-item--active' : ''}`}
                            onClick={() => handleNavClick(item)}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            </nav>
            <main className='admin-panel__content'>
                {activeTab === 'Add Shift' && (
                    <form className='admin-panel__form' onSubmit={handleSubmit}>
                        <div className='admin-panel__flex-container'>
                            <section className='admin-panel__section admin-panel__left'>
                                <h2 className='admin-panel__subheader'>Add Shift</h2>
                                <div className='admin-panel__group'>
                                    <label className='admin-panel__label'>Employee
                                        <select
                                            value={selectedEmployee}
                                            onChange={e => {
                                                setSelectedEmployee(e.target.value || ''); // directly use the value which is employee id
                                            }}

                                            className='admin-panel__input'
                                        >
                                            <option value="">Select Employee</option>
                                            {employees.map((employee) => (
                                                <option key={employee.user_id} value={employee.user_id}>
                                                    {employee.first_name + ' ' + employee.last_name}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    <label className='admin-panel__label'>Shift Date
                                        <DatePicker
                                            selected={shiftDate}
                                            onChange={date => setShiftDate(date)}
                                            className='admin-panel__input'
                                            dateFormat='yyyy-MM-dd'
                                        />
                                    </label>
                                    <label className='admin-panel__label'>Start Time
                                        <select
                                            value={startTime}
                                            onChange={e => setStartTime(e.target.value)}
                                            className='admin-panel__input'
                                        >
                                            <option value="">Select Start Time</option>
                                            {shiftTimes.map((time, index) => (
                                                <option key={index} value={time}>{time}</option>
                                            ))}
                                        </select>
                                    </label>
                                    <label className='admin-panel__label'>Finish Time
                                        <select
                                            value={finishTime}
                                            onChange={e => setFinishTime(e.target.value)}
                                            className='admin-panel__input'
                                        >
                                            <option value="">Select Finish Time</option>
                                            {shiftTimes.map((time, index) => (
                                                <option key={index} value={time}>{time}</option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            </section>
                            <section className='admin-panel__section admin-panel__right'>
                                <div className='admin-panel__group'>
                                    <label className='admin-panel__label'>Repeat Frequency
                                        <select
                                            value={repeatFrequency}
                                            onChange={e => setRepeatFrequency(e.target.value)}
                                            className='admin-panel__input'
                                        >
                                            <option value="">Select Frequency</option>
                                            <option value="daily">Daily</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="bi-weekly">Bi-Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </select>
                                    </label>
                                    <label className='admin-panel__label'>Repeat Duration
                                        <select
                                            value={repeatDuration}
                                            onChange={e => setRepeatDuration(e.target.value)}
                                            className='admin-panel__input'
                                        >
                                            <option value="">Select Duration</option>
                                            <option value="1 month">1 Month</option>
                                            <option value="3 months">3 Months</option>
                                            <option value="6 months">6 Months</option>
                                            <option value="1 year">1 Year</option>
                                        </select>
                                    </label>
                                </div>
                            </section>
                        </div>
                        <div className='admin-panel__actions'>
                            <button type='button' className='admin-panel__button admin-panel__button--cancel'>Cancel</button>
                            <button type='submit' className='admin-panel__button admin-panel__button--add'>+ Add Shift</button>
                        </div>
                    </form>
                )}
            </main>
        </div>
    );
}

export default AdminPanel;
