import React, { useEffect, useState } from 'react';
import { useShift } from '../../context/ShiftContext';
import axios from 'axios';
import './Calendar.scss';

function Calendar() {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [shifts, setShifts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { shiftsUpdated } = useShift();

    useEffect(() => {
        const fetchShifts = async () => {
            try {
                const { data } = await axios.get(`https://www.skedteam.com/shifts`);
                const transformedShifts = data.map(shift => ({
                    staffId: `${shift.user_id}`,
                    date: new Date(shift.shift_date),
                    time: shift.shift_time,
                    name: `${shift.first_name} ${shift.last_name}`
                }));
                setShifts(transformedShifts);
            } catch (error) {
                console.error('Failed to fetch shifts:', error);
            }
        };
        fetchShifts();
    }, [shiftsUpdated]);

    // Dates and Days
    const formatDate = (date) => date.toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric' });
    const startOfWeek = (date) => new Date(date.setDate(date.getDate() - date.getDay()));
    const datesOfWeek = [...Array(7).keys()].map(i => new Date(startOfWeek(new Date(currentDate)).setDate(startOfWeek(new Date(currentDate)).getDate() + i)));
    const isCurrentDay = (date) => new Date().toDateString() === date.toDateString();

    // Navigation
    const prevWeek = () => setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
    const nextWeek = () => setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));

    const staffMembers = [...new Map(shifts.map(shift => [shift.staffId, { id: shift.staffId, name: shift.name }])).values()];

    // Search Staff
    const filteredStaffMembers = staffMembers.filter(member =>
        member.name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <div className="calendar">
            <div className="calendar__nav">
                <button onClick={prevWeek}>❮ Prev</button>
                <button onClick={nextWeek}>Next ❯</button>
            </div>
            <div className="calendar__header">
                <input
                    type="text"
                    placeholder="Search users"
                    className="calendar__search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {datesOfWeek.map((date, index) => (
                    <div key={index} className={`calendar__cell ${isCurrentDay(date) ? 'calendar__cell--current' : ''}`}>{formatDate(date)}</div>
                ))}
            </div>
            {filteredStaffMembers.map(member => ( // Use filteredStaffMembers here
                <div key={member.id} className="calendar__row">
                    <div className="calendar__cell calendar__cell--name">{member.name}</div>
                    {datesOfWeek.map((date, index) => {
                        const shift = shifts.find(shift => shift.staffId === member.id && shift.date.toDateString() === date.toDateString());
                        return (
                            <div key={index} className={`calendar__cell ${isCurrentDay(date) ? 'calendar__cell--current' : ''}`}>
                                <div className={`calendar__shift-tile ${shift && shift.time ? 'calendar__shift-tile--filled' : ''}`}>
                                    {shift ? shift.time : ''}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}

export default Calendar;